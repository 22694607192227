import styled, { createGlobalStyle } from "styled-components";
import { Download } from "styled-icons/boxicons-solid/Download";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Nudista Light";
    src: url("https://static1.bimproject.cloud/fonts/nudista/nudista-light.otf");
  }

  @font-face {
    font-family: "Nudista Medium";
    src: url("https://static1.bimproject.cloud/fonts/nudista/nudista-medium.otf");
  }

  @font-face {
    font-family: "Nudista Bold";
    src: url("https://static1.bimproject.cloud/fonts/nudista/nudista-bold.otf");
  }

  body {
    font-family: "Nudista Medium", sans-serif;
    font-size: 1.1rem;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }
`;

export const DownloadPage = styled.div`
  h1, h2, h3, h4, h5, h6 {
    font-family: "Abril Fatface", serif;
    font-weight: normal;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  a {
    color: inherit;
  }
`;

export const PageSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const IntroWrapper = styled(PageSection)`
  background: #f2f2f2;
  border-top: 7px solid ${ ({ color }) => color };
`;

export const Intro = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  padding: 50px 20px;

  @media (max-width: 900px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
  }

  .logo {
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      max-width: 80%;
    }
  }

  .title {
    p {
      span {
        font-family: "Nudista Bold", sans-serif;
      }
    }
  }
`;

export const DownloadIcon = styled(Download)`
  margin-right: 10px;
  width: 1.5rem;
`;

export const StyledLibrary = styled.div`
  display: grid;
  font-family: "Nudista Light", sans-serif;
  font-size: 1rem;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;

  @media (max-width: 960px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
  }

  & > div {
    border: 1px solid #ededed;
    border-radius: 10px;
    margin: 20px;
  }

`;

export const LibraryDescription = styled.div``;

export const LibraryTitle = styled.div`
  align-items: center;
  background: #f2f2f2;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 30px;

  h2 {
    margin: 0;
  }

  span {
    color: #aaa;
    font-family: "Nudista Light", sans-serif;
    font-size: 1rem;
  }

  img {
    max-width: 30%;
  }
`;

export const LibraryContent = styled.div`
  padding: 30px;

  .download {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;

    a {
      align-items: center;
      background: ${ ({ color }) => color };
      border-radius: 25px;
      color: #fff;
      display: flex;
      flex-direction: row;
      font-family: "Nudista Medium", sans-serif;
      font-size: 1.2rem;
      height: 50px;
      justify-content: center;
      padding: 0 20px;
      text-decoration: none;
      transition: background-color 200ms;

      &:hover {
        background: #103160;
      }
    }

  }
`;

export const LibrarySignature = styled.div`
  color: #aaa;
  font-size: .9rem;
  padding: 0 30px 30px;

  .separator {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    hr {
      border: .5px solid #ededed;
      flex: auto;
      margin-left: 10px;
    }

  }

  .signature {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      filter: grayscale(100%);
      flex: none;
      margin-right: 10px;
      max-width: 30%;
      opacity: 50%;
    }

    span {
      width: 50%;

      a {
        color: inherit !important;
        font-family: "Nudista Regular", sans-serif;
      }

    }

  }
`;

export const LibraryPreview = styled.div`
  background: #f2f2f2;
  overflow: hidden;
  position: relative;

  @media (max-width: 960px) {
    display: none;
  }

  img {
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
