import React from "react";
import Helmet from "react-helmet";

import * as S from "../styles/oknaeu/styled";
import LogoOknaEU from "../images/oknaeu/oknaeu-logo.png";
import LogoCC from "../images/oknaeu/cc-logo.png";
import LogoAC from "../images/oknaeu/ac-logo.png";
import LogoRA from "../images/oknaeu/ra-logo.png";
import Preview1 from "../images/oknaeu/preview1.png";
import Preview2 from "../images/oknaeu/preview2.png";
import SEO from "../components/seo";


function Library(
    {
        color,
        title,
        lastUpdate,
        titleLogo,
        text,
        downloadLabel,
        downloadUrl,
        developerLogo,
        support,
        preview,
    }
) {
    return (
        <S.StyledLibrary color={ color }>
            <S.LibraryDescription className="description">
                <S.LibraryTitle>
                    <div>
                        <h2>{ title }</h2>
                        <span>poslední aktualizace: { lastUpdate }</span>
                    </div>
                    <img src={ titleLogo } alt="software logo" />
                </S.LibraryTitle>
                <S.LibraryContent color={ color }>
                    {
                        text.map(
                            (t, i) => (
                                <p key={ i }>
                                    { t }
                                </p>
                            )
                        )
                    }
                    <div className="download">
                        <a href={ downloadUrl }>
                            <span>
                                <S.DownloadIcon />
                                { downloadLabel }
                            </span>
                        </a>
                    </div>
                </S.LibraryContent>
                <S.LibrarySignature>
                    <div className="separator">
                        zpracovatel
                        <hr />
                    </div>
                    <div className="signature">
                        <img src={ developerLogo } alt="logo zpracovatele" />
                        <span>dotazy a připomínky prosím posílejte na: <a href={ `mailto:${ support }` }>{ support }</a></span>
                    </div>
                </S.LibrarySignature>
            </S.LibraryDescription>
            <S.LibraryPreview>
                <img src={ preview } alt="náhled" />
            </S.LibraryPreview>
        </S.StyledLibrary>
    );
}

export default function OknaEU() {
    return (
        <S.DownloadPage>
            <SEO
                title="OKNA.EU"
                lang="cs"
            />
            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap" rel="stylesheet" />
            </Helmet>
            <S.GlobalStyle />
            <S.IntroWrapper color="#103f8f">
                <S.Intro>
                    <div className="logo">
                        <img src={ LogoOknaEU } alt="OKNA.EU logo" />
                    </div>
                    <div className="title">
                        <p>Vážení obchodní partneři,<br /><br />naše společnost hledá cesty, jak Vám pomáhat v přípravě a realizaci Vašich projektů. Snažíme se poskytovat technickou podporu ať už ve sdílení knowhow tak v předávání technické dokumentace. Vzhledem k tomu, že se neustále snažíme reagovat na aktuální a budoucí potřeby trhu a sledujeme technologické trendy, vnímáme i potřebu poskytovat technická data pro nejdůležitější BIM platformy, které se dostávají do popředí návrhových systémů. Proto jsme exkluzivně pro Vás připravili širokou sadu BIM prvků, které reflektují námi nabízený sortiment. Tyto prvky jsou navrženy sofistikovaně tak, aby se Vás snažily navigovat při práci s nimi. Vzhledem k výše zmíněnému bychom Vás chtěli požádat o cennou zpětnou vazbu, kterou potřebujeme pro ověření, zda náš přístup byl správný a vyrobené BIM prvky reflektují Vaše potřeby. Budeme rádi za pozitivní i negativní hodnocení, či doporučení.</p>
                        <p>Knihovna oken a dveří je zpracována pro projekční BIM softwary Archicad a Revit. Obsahuje geometrické varianty hliníkových <span>oken</span>, <span>dveří</span> a <span>posuvných oken</span>, které zahrnují produktové řady EXCLUSIV SI 75, EXCLUSIV HI 80, EXCLUSIV HI 77, EXCLUSIV HI 77 SL, EXCLUSIV HI 77 ST, EXCLUSIV HI PSK, EXCLUSIV D72, EXCLUSIV D92 a EXCLUSIV D75. Součástí jsou také varianty s vloženými křídly nebo napojování v rozích.</p>
                    </div>
                </S.Intro>
            </S.IntroWrapper>
            <S.PageSection style={ { margin: "40px 0 80px" } }>
                <Library
                    color="#103f8f"
                    title="Knihovna pro Revit"
                    lastUpdate="30.9.2020"
                    titleLogo={ LogoRA }
                    text={
                        [
                            "Jednotlivé prvky reprezentují kombinace produktové řady a geometrie okna nebo dveří. Knihovna obsahuje celkem 50 souborů, ukázkový projekt a soubor sdílených parametrů.",
                            "Postup instalace: Načtení jednotlivých prvků do projektu běžným způsobem (Load Family).",
                            "Knihovna je kompatibilní s verzí Revitu 2019 a vyšší.",
                        ]
                    }
                    downloadLabel="Stáhnout knihovnu (901,1 MB)"
                    downloadUrl="http://data2.bimproject.cz/libraries/OKNA.EU.REVIT.zip"
                    developerLogo={ LogoCC }
                    support="support@cadconsulting.cz"
                    preview={ Preview1 }
                />
                <Library
                    color="#103f8f"
                    title="Knihovna pro Archicad"
                    lastUpdate="30.9.2020"
                    titleLogo={ LogoAC }
                    text={
                        [
                            "Struktura knihovny vychází z běžných knihoven Archicadu, je tedy založena primárně na výběru geometrie. Poté si může uživatel volit produktovou řadu, typ otevírání a barevnost ze vzorníku RAL. Objekty obsahují všechna nastavení, na které jsou uživatelé zvyklí, jako je podrobnost dle Zobrazení modelu, zalamování omítky nebo volba typu ostění.",
                            "Postup instalace: Načtení knihovny do projektu běžným způsobem přes Soubor - Knihovny a objekty - Správce knihoven.",
                            "Knihovna je kompatibilní s verzí Archicadu 20 a vyšší.",
                        ]
                    }
                    downloadLabel="Stáhnout knihovnu (10,6 MB)"
                    downloadUrl="http://data2.bimproject.cz/libraries/OKNA.EU.ARCHICAD.zip"
                    developerLogo="https://static1.bimproject.cloud/bp/logo.svg"
                    support="support@bimproject.cz"
                    preview={ Preview2 }
                />
            </S.PageSection>
        </S.DownloadPage>
    );
}
